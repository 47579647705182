import Client from './clients/axios-client';
  const resource = '/api/Course';

  export default {
    getCertificates() {
      //return Client.get(`${resource}/course/get-certificates`);
      return Client.get(`${resource}/get-certificates`)
      .then((response) => {
        if (response && response.data) {
          return response.data;
        } else {
          console.error('No data in responseneyney');
        }
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
    },
    getCourses() {
      return Client.get(`${resource}/get-courses`)
      .then((response) => {
        if (response && response.data) {
          return response.data;
        } else {
          console.error('No data in responseneyney');
        }
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
    },
    getCoursesBycertId(certId) {
      return Client.get(`${resource}/get-coursesbycertId?certId=${certId}`)
      .then((response) => {
        if (response && response.data) {
         
          return response.data;
        } else {
          console.error('No data in response');
        }
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
    },
    getCoursesById(id) {
      return Client.get(`${resource}/get-coursesbyid?ID=${id}`)
      .then((response) => {
        if (response && response.data) {
          return response.data;
        } else {
          console.error('No data in response');
        }
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
    },
    createCertificate(payload) {
      return Client.post(`${resource}/add-certificates`, payload);
    },
    createCourse(payload) {
      return Client.post(`${resource}/add-course`, payload);
    },
    removeCertificate(id) {
      return Client.delete(`${resource}/course/${id}`);
    },
    editCourse(payload) {
      return Client.put(`${resource}/edit-course/`, payload);
    },
    editCertificate(payload) {
      return Client.put(`${resource}/edit-certificates/`, payload);
    },
    softDeleteCourse(id) {
      return Client.put(`${resource}/soft-delete-course?id=${id}`);
    }
  };


import service from '../../services/factory-service';
import Vue from 'vue';

const projAppService = service.get("project");
const companyService = service.get("company");

const state = {
  project: null,
  status: '',
  forApproval: [],
  list: [],
  application: null,
  applicationList: [],
  applicationCount: 0,
  statusCount: {},
  talentCount: 0,
  loadingStatus: false,
  ids: [],
  headerDetails: null,
  activities: [],
};

const getters = {
  status: (projectState) => projectState.status,
  project: (projectState) => projectState.project,
  forApproval: (projectState) => projectState.forApproval,
  list: (projectState) => projectState.list,
  application: (projectState) => projectState.application,
  applicationList: (projectState) => projectState.applicationList,
  applicationCount: (projectState) => projectState.applicationCount,
  statusCount: (projectState) => projectState.statusCount,
  talentCount: (projectState) => projectState.talentCount,
  loadingStatus: (projectState) => projectState.loadingStatus,
  ids: (projectState) => projectState.ids,
  headerDetails: (projectState) => projectState.headerDetails,
  activities: (projectState) => projectState.activities,
};

const actions = {
  getForApproval: ({ commit }) => {
    commit('projectRequest');
    projAppService.getProjectByStatusId(2)
      .then((result) => {
        commit('projectForApproval', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  getPublish: ({ commit }) => {
    commit('projectRequest');
    projAppService.getProjectByStatusId(3)
      .then((result) => {
        commit('projectList', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  getPublishedByBatch: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('projectRequest');
      projAppService.getProjectByStatusIdBatch(payload.index, payload.size, 3)
        .then((result) => {
          commit('projectList', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('projectError', errors);
            reject(errors);
          })
    });
  },
  getProjectByCriteria: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('projectRequest');
      projAppService.getProjectByCriteria(payload)
      
        .then((result) => {
          commit('projectList', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('projectError', errors);
            reject(errors);
          })
    });
  },
  getProjectByBatch: ({ commit }, payload) => {
    commit('projectRequest');
    projAppService.getProjectByBatch(payload)
      .then((result) => {
        commit('projectForApproval', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  get: ({ commit }) => {
    commit('projectRequest');
    projAppService.getProjects()
      .then((result) => {
        commit('projectList', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  getProjectById: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('projectRequest');
      projAppService.getProjectById(id)
        .then((result) => {
          commit('projectSuccess', result.data);
          resolve();
        },
          (errors) => {
            commit('projectError', errors);
            reject();
          });
    });
  },
  getProjectByIdForHeaders: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('projectRequest');
      projAppService.getProjectById(id)
        .then((result) => {
          commit('projectHeader', result.data);
          resolve();
        },
          (errors) => {
            commit('projectError', errors);
            reject();
          });
    });
  },
  getProjectByCompanyIds: ({ commit }, userId) => {
    commit('projectRequest');
    commit('projectLoading', true);
    companyService.getByUserId(userId)
      .then((result) => {
        var ids = [];
        for (var prop in result.data) {
          ids.push(result.data[prop].ID);
        }

        projAppService.getProjectByCompanyIds(ids)
          .then((result) => {
            commit('projectList', result.data);
            commit('projectLoading', false);
          },
            (errors) => {
              commit('projectError', errors);
              commit('projectLoading', false);
            });
      },
        (errors) => {
          commit('companyError', errors);
          commit('projectLoading', false);
        });
  },
  getProjectIdByCompanyIds: ({ commit }, userId) => {
    commit('projectRequest');
    companyService.getByUserId(userId)
      .then((result) => {
        var ids = [];
        for (var prop in result.data) {
          ids.push(result.data[prop].ID);
        }

        projAppService.getProjectIdByCompanyIds(ids)
          .then((result) => {
            commit('projectIdList', result.data);
          },
            (errors) => {
              commit('projectError', errors);
            });
      },
        (errors) => {
          commit('companyError', errors);
        });
  },
  getProjectByTeamIds: ({ commit }, id) => {
    commit('projectRequest');
    projAppService.getProjectByTeamIds(id)
      .then((result) => {
        commit('projectList', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  createProject: ({ commit }, request) => {
    commit('projectRequest');
    projAppService.createProject(request)
      .then((request) => {
        commit('projectSuccess', request.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  updateProject: ({ commit }, request) => {
    commit('projectRequest');
    projAppService.updateProject(request)
      .then((request) => {
        commit('projectSuccess', request.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  deleteProject: ({ commit, dispatch }, id) => {
    commit('projectRequest');
    projAppService.deleteProject(id)
      .then(() => {
        commit('projectSuccess');
        dispatch('project/get', id, { root: true });
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  submitProject: ({ commit }, payload) => {
    commit('projectRequest');
    projAppService.submitProject(payload)
      .then((resp) => {
        commit('projectSuccess', resp.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  approveProject: ({ commit }, id) => {
    commit('projectRequest');
    projAppService.approveProject(id)
      .then((result) => {
        commit('projectForApproval', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  approveBulkProject: ({ commit }, payload) => {
    commit('projectRequest');
    projAppService.approveBulkProject(payload)
      .then((result) => {
        commit('projectForApproval', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  rejectProject: ({ commit }, payload) => {
    commit('projectRequest');
    projAppService.rejectProject(payload)
      .then((result) => {
        commit('projectForApproval', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  rejectBulkProject: ({ commit }, payload) => {
    commit('projectRequest');
    projAppService.rejectBulkProject(payload)
      .then((result) => {
        commit('projectForApproval', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  addProjectApplication: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('projectRequest');
      projAppService.createProjectApplication(payload)
        .then((result) => {
          commit('projectApplicationSuccess', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('projectError', errors);
            reject(errors);
          });
    });
  },
  getApplicationsById: ({ commit }, id) => {
    commit('projectRequest');
    commit('projectLoading', true);
    projAppService.getProjectApplicationsById(id)
      .then((result) => {
        commit('projectApplicationList', result.data);
        commit('projectLoading', false);
      },
        (errors) => {
          commit('projectError', errors);
          commit('projectLoading', false);
        });
  },
  getApplicationsCountById: ({ commit }, id) => {
    commit('projectRequest');
    projAppService.getProjectApplicationsCountById(id)
      .then((result) => {
        commit('projectApplicationCount', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  getApplicationTeamsById: ({ commit }, id) => {
    commit('projectRequest');
    projAppService.getProjectApplicationTeamsById(id)
      .then((result) => {
        commit('projectApplicationList', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  getApplicationByUserId: ({ commit }) => {
    commit('projectRequest');
    projAppService.getProjectApplicationByUserId()
      .then((result) => {
        commit('projectApplicationList', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  getApplicationAppliedByUser: ({ commit }) => {
    commit('projectRequest');
    projAppService.getProjectApplicationAppliedByUser()
      .then((result) => {
        commit('userApplicationList', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  getApplicationHistoryByUser: ({ commit }) => {
    commit('projectRequest');
    projAppService.getProjectApplicationHistoryByUser()
      .then((result) => {
        commit('userApplicationHistoryList', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  getApplicationByCompanyId: ({ commit }) => {
    commit('projectRequest');
    projAppService.getProjectApplicationByCompanyId()
      .then((result) => {
        commit('projectApplicationList', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  getAppilcationAdminDashboard: ({ commit }, count) => {
    commit('projectRequest');
    projAppService.getProjectApplicationAdminDashboard(count)
      .then((result) => {
        commit('projectApplicationList', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  removeApplication: ({ commit }, payload) => {
    commit('projectRequest');
    projAppService.removeApplication(payload)
      .then(() => {
        commit('projectSuccess');
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  approveApplication: ({ commit }, payload) => {
    commit('projectRequest');
    projAppService.approveApplication(payload)
      .then((response) => {
        commit('projectSuccess', response.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  projectStatusCount: ({ commit }) => {
    commit('projectRequest');
    return new Promise((resolve, reject) => {
      projAppService.projectStatusCount()
        .then((response) => {
          commit('projectCount', response.data);
          resolve(response.data);
        },
          (errors) => {
            commit('projectError', errors);
            reject(errors);
          });
    });
  },
  clientStatusCount: ({ commit }) => {
    commit('projectRequest');
    return new Promise((resolve, reject) => {
      projAppService.clientStatusCount()
        .then((response) => {
          commit('projectCount', response.data);
          resolve(response.data);
        },
          (errors) => {
            commit('projectError', errors);
            reject(errors);
          });
    });
  },
  clientUtilizedTalents: ({ commit }) => {
    commit('projectRequest');
    return new Promise((resolve, reject) => {
      projAppService.clientUtilizedTalents()
        .then((response) => {
          commit('projectTalentCount', response.data);
          resolve(response.data);
        },
          (errors) => {
            commit('projectError', errors);
            reject(errors);
          });
    });
  },
  uploadFile: ({ commit }, request) => {
    commit('projectRequest');
    commit('projectLoading', true);
    projAppService.addFile(request)
      .then((result) => {
        commit('projectSuccess', result.data);
        commit('projectLoading', false);
      },
        (errors) => {
          commit('projectError', errors);
          commit('projectLoading', false);
        });
  },
  removeFile: ({ commit }, request) => {
    commit('projectRequest');
    commit('projectLoading', true);
    projAppService.removeFile(request)
      .then((result) => {
        commit('projectSuccess', result.data);
        commit('projectLoading', false);
      },
        (errors) => {
          commit('projectError', errors);
          commit('projectLoading', false);
        });
  },
  getFile: ({ commit }, request) => {
    commit('projectRequest');
    commit('projectLoading', true);
    projAppService.getFile(request)
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', request.Name);
        document.body.appendChild(link);
        link.click();

        commit('projectLoading', false);
      },
        (errors) => {
          commit('projectError', errors);
          commit('projectLoading', false);
        });
  },
  updateProjectRating: ({ commit }, request) => {
    return new Promise((resolve, reject) => {
      commit('projectRequest');
      projAppService.updateProjectRating(request)
        .then((result) => {
          commit('projectList', result.data);
          resolve();
        },
          (errors) => {
            commit('projectError', errors);
            reject(errors);
          });
    });
  },
  getProjectsByCompanyId: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('projectRequest');
      projAppService.getProjectsByCompanyId(payload)
        .then((result) => {
          commit('projectList', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('projectError', errors);
            reject(errors);
          });
    });
  },
  closeOutProject: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('projectRequest');
      projAppService.closeOutProject(payload)
        .then((result) => {
          commit('projectSuccess', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('projectError', errors);
            reject(errors);
          });
    });
  },
  getProjectActivities: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('projectRequest')
      projAppService.getProjectActivities(payload)
        .then((result) => {
          commit('activityList', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('projectError', errors);
            reject(errors);
          });
    });
  },

  saveProjects: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('projectRequest');
      projAppService.saveavailableProject(payload)
        .then((result) => {
          commit('projectApplicationSuccess', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('projectError', errors);
            reject(errors);
          });
    });
  },

  getSavedJobsByUser: ({ commit }) => {
    commit('projectRequest');
    projAppService.getSavedJobsAppliedByUser()
      .then((result) => {
        commit('userSavedProjects', result.data);
      },
        (errors) => {
          commit('projectError', errors);
        });
  },
  
};

const mutations = {
  projectRequest: (projectState) => {
    projectState.status = 'attempting request for project profile data';
  },
  projectSuccess: (projectState, projectResp) => {
    projectState.status = 'success';
    Vue.set(projectState, 'project', projectResp);
  },
  projectHeader: (projectState, projectResp) => {
    projectState.status = 'success';
    Vue.set(projectState, 'headerDetails', projectResp);
  },
  projectApplicationSuccess: (projectState, data) => {
    projectState.status = 'success';
    Vue.set(projectState, 'application', data);
  },
  projectApplicationList: (projectState, list) => {
    projectState.status = 'success';
    Vue.set(projectState, 'applicationList', list);
  },
  userApplicationList: (projectState, list) => {
    projectState.status = 'success';
    Vue.set(projectState, 'userapplicationList', list);
  },
  userSavedProjects: (projectState, list) => {
    projectState.status = 'success';
    Vue.set(projectState, 'usersavedprojectList', list);
  },
  userApplicationHistoryList: (projectState, list) => {
    projectState.status = 'success';
    Vue.set(projectState, 'userapplicationhistoryList', list);
  },
  projectApplicationCount: (projectState, response) => {
    projectState.status = 'success';
    Vue.set(projectState, 'applicationCount', response.Count);
  },
  projectForApproval: (projectState, list) => {
    projectState.status = 'success';
    Vue.set(projectState, 'forApproval', list);
  },
  projectList: (projectState, list) => {
    projectState.status = 'success';
    Vue.set(projectState, 'list', list);
  },
  projectIdList: (projectState, list) => {
    projectState.status = 'success';
    Vue.set(projectState, 'ids', list);
  },
  projectError: (projectState) => {
    projectState.status = 'error';
  },
  projectLogout: (projectState) => {
    projectState.status = '';
  },
  projectCount: (projectState, response) => {
    projectState.status = 'success';
    Vue.set(projectState, 'statusCount', response);
  },
  projectTalentCount: (projectState, response) => {
    projectState.status = 'success';
    Vue.set(projectState, 'talentCount', response);
  },
  projectLoading: (projectState, response) => {
    projectState.loadingStatus = response;
  },
  ratingRequest: (projectState) => {
    projectState.Status = 'attempting request updating project rating';
  },
  ratingSuccess: (projectState, projectResp) => {
    projectState.status = 'success';
    Vue.set(projectState, 'project', projectResp);
  },
  ratingError: (projectState) => {
    projectState.status = 'failed';
  },
  activityList: (projectState, list) => {
    projectState.status = 'success';
    Vue.set(projectState, 'activities', list);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
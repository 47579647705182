import Client from './clients/axios-client';
const resource = '/api/skill';

export default {
  getSkillsByStatusId(statusId) {
    return Client.get(`${resource}/skillsByStatus/${statusId}`);
  },
  getSkills() {
    return Client.get(`${resource}/allskills`);
  },
  getSkillsBatch(params) {
    return Client.get(`${resource}/skills/${params.index}/${params.size}/${params.statusId}`);
  },
  // createSkill(payload) {
  //   return Client.post(`${resource}/skills/`, payload);
  // },
  createSkill(payload) {
    console.log('Adding skill:', payload);  // Log the payload being sent
  
    return Client.post(`${resource}/skills/`, payload)
      .then(response => {
        console.log('Skill added successfully:', response.data);  // Log the response
        return response;  // Ensure the full response is returned
      })
      .catch(error => {
        console.error('Error adding skill:', error);  // Log any errors
        throw error;  // Re-throw the error so it can be caught in the action
      });
  },
  approveSkill(id) {
    return Client.patch(`${resource}/skills/${id}/activate`);
  },
  rejectSkill(id) {
    return Client.delete(`${resource}/skills/${id}`);
  },
  removeSkill(id) {
    return Client.delete(`${resource}/skills/${id}`);
  },
  editSkill(payload) {
    return Client.put(`${resource}/skills/${payload.ID}`, payload);
  }
};


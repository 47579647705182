import service from '../../services/factory-service';
import Vue from 'vue';

const certificatesService = service.get("certificate");

const state = { 
  certificate: {}, 
  status: '', 
  list: [], 
  courseList: [], 
  courseListByCertId: [],
  editCourseStatus: '',
  editCertificateStatus: '',
  deleteCourseStatus: ''
};

const getters = {
  certificate: (certificateState) => certificateState.certificate,
  list: (certificateState) => certificateState.list,
  courseList: (certificateState) => certificateState.courseList,
  courseListByCertId: (certificateState) => certificateState.courseListByCertId,
  editCourseStatus: (certificateState) => certificateState.editCourseStatus, 
  editCertificateStatus: (certificateState) => certificateState.editCertificateStatus,
  deleteCourseStatus: (certificateState) => certificateState.deleteCourseStatus
};

const actions = {

  getCertificates: ({ commit }) => {
    commit('certificateRequest');
      certificatesService.getCertificates()
      .then((result) => {
        if (result) {
          commit('certificateList', result);
        } else {
          console.error('No data in response');
        }
      },
        (errors) => {
          commit('certificateError', errors);
        });
  },

  getCourses: ({ commit }) => {
    commit('courseRequest');
    certificatesService.getCourses()
      .then((result) => {
        if (result) {
          commit('courseList', result);
        } else {
          console.error('No data in response');
        }
      },
        (errors) => {
          commit('courseError', errors);
        });
  },
  getCoursesBycertId: ({ commit }, certid) => {
    commit('courseRequest');
    certificatesService.getCoursesBycertId(certid)
      .then((result) => {
        if (result) {
          commit('courseListByCertId', result);
        } else {
          console.error('No data in response');
        }
      },
        (errors) => {
          commit('courseError', errors);
        });
  },
  getCoursesById: ({ commit }, id) => {
    commit('courseRequest');
    certificatesService.getCoursesById(id)
      .then((result) => {
        if (result) {
          commit('courseListById', result);
        } else {
          console.error('No data in response');
        }
      },
        (errors) => {
          commit('courseError', errors);
        });
  },
  addCertificate: ({commit, dispatch}, certificate) => {
    commit('certificateRequest');
    certificatesService.createCertificate(certificate)
      .then((result) => {
        commit('certificateSuccess', result.data);
        dispatch('certificate/addCertificate', null, { root: true });
      },
        (errors) => {
          commit('certificateError', errors);
        });
  },

  addCourse({ commit, dispatch }, certificate) {
    commit('certificateRequest');
    certificatesService.createCourse(certificate)
      .then((result) => {
        commit('certificateSuccess', result.data);
        dispatch('certificate/addCourse', null, { root: true });
      })
      .catch((errors) => {
        commit('certificateError', errors);
      });
  },

  editCourse({ commit }, updatedCourse) {
    commit('courseEditRequest');
    certificatesService.editCourse(updatedCourse)
      .then((result) => {
        commit('courseEditSuccess', result.data);
      })
      .catch((errors) => {
        commit('courseEditError', errors);
      });
  },

  editCertificate({ commit }, updatedCertificate) {
    commit('certificateEditRequest');
    certificatesService.editCertificate(updatedCertificate)
      .then(result => {
        commit('certificateEditSuccess', result.data);
      })
      .catch(errors => {
        commit('certificateEditError', errors);
      });
  },

  softDeleteCourse({ commit }, courseId) {
    commit('courseDeleteRequest');
    certificatesService.softDeleteCourse(courseId)
      .then(() => {
        commit('courseDeleteSuccess', courseId);
      })
      .catch((errors) => {
        commit('courseDeleteError', errors);
      });
  }


};

const mutations = {
  certificateRequest: (certificateState) => {
    certificateState.status = 'attempting request for certificate data';
  },
  courseRequest: (certificateState) => {
    certificateState.status = 'attempting request for course data';
  },
  certificateSuccess: (certificateState, certificateResp) => {
    certificateState.status = 'success';
    Vue.set(certificateState, 'profile', certificateResp);
  },
  courseSuccess: (courseState, courseResp) => {
    courseState.status = 'success';
    Vue.set(courseState, 'profile', courseResp);
  },
  courseEditRequest: (certificateState) => {
    certificateState.editCourseStatus = 'attempting to edit course data';
  },
  certificateEditRequest(state) {
    state.editCertificateStatus = 'attempting to edit certificate data'; // Added state update
  },
  certificateList: (certificateState, list) => {
    certificateState.status = 'success';
    Vue.set(certificateState, 'list', list);
  },
  courseList: (courseState, list) => {
    courseState.status = 'success';
    Vue.set(courseState, 'courseList', list);
  },
  courseListByCertId: (courseState, list) => {
    courseState.status = 'success';
    Vue.set(courseState, 'courseListByCertId', list);
  },
  courseListById: (courseState, list) => {
    courseState.status = 'success';
    Vue.set(courseState, 'list', list);
  },
  courseEditSuccess: (certificateState, updatedCourse) => {
    certificateState.editCourseStatus = 'success';
    // Update the course in the state
    const index = certificateState.courseList.findIndex(course => course.ID === updatedCourse.ID);
    if (index !== -1) {
      Vue.set(certificateState.courseList, index, updatedCourse);
    }
    // Also update in courseListByCertId if necessary
    const certIndex = certificateState.courseListByCertId.findIndex(course => course.ID === updatedCourse.ID);
    if (certIndex !== -1) {
      Vue.set(certificateState.courseListByCertId, certIndex, updatedCourse);
    }
  },
  certificateEditSuccess(certificateState, updatedCertificate) {
    certificateState.editCertificateStatus = 'success';
    const index = certificateState.list.findIndex(cert => cert.ID === updatedCertificate.ID);
    if (index !== -1) {
      Vue.set(certificateState.list, index, updatedCertificate);
    }
  },
  courseDeleteRequest: (certificateState) => {
    certificateState.deleteCourseStatus = 'attempting to delete course';
  },
  courseDeleteSuccess: (certificateState, courseId) => {
    certificateState.deleteCourseStatus = 'success';
    const index = certificateState.courseList.findIndex(course => course.ID === courseId);
    if (index !== -1) {
      Vue.set(certificateState.courseList, index, { ...certificateState.courseList[index], IsDeleted: true });
    }
    const certIndex = certificateState.courseListByCertId.findIndex(course => course.ID === courseId);
    if (certIndex !== -1) {
      Vue.set(certificateState.courseListByCertId, certIndex, { ...certificateState.courseListByCertId[certIndex], IsDeleted: true });
    }
  },
  courseDeleteError: (certificateState) => {
    certificateState.deleteCourseStatus = 'error';
  },
  certificateError: (certificateState) => {
    certificateState.status = 'error';
  },
  courseError: (certificateState) => {
    certificateState.status = 'error';
  },
  courseEditError: (certificateState) => {
    certificateState.editCourseStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
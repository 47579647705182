import Client from './clients/axios-client';
const resource = '/api';

export default {
	getProjectByStatusId(statusId) {
		return Client.get(`${resource}/projectmonitoring/projectsByStatus/${statusId}`);
	},
	getProjectByStatusIdBatch(index, size, statusId) {
		return Client.get(`${resource}/projectmonitoring/projects/${index}/${size}/${statusId}`);
	},
	getProjectByBatch(payload) {
		return Client.get(`${resource}/projectmonitoring/projects/${payload.index}/${payload.size}/${payload.statusId}`);
	},
	getProjectByCriteria(payload) {
		return Client.post(`${resource}/projectmonitoring/published`, payload);
	},
	getProjects() {
		return Client.get(`${resource}/projectmonitoring/projects`);
	},
	getProjectById(id) {
		return Client.get(`${resource}/projectmonitoring/projectsbyId/${id}`);
	},
	getProjectByCompanyIds(ids) {
		return Client.post(`${resource}/projectmonitoring/projectsByCompanyIds`, { CompanyIds: ids });
	},
	getProjectIdByCompanyIds(ids) {
		return Client.post(`${resource}/projectmonitoring/projectids`, { CompanyIds: ids });
	},
	getProjectByTeamIds(id) {
		return Client.get(`${resource}/projectmonitoring/projectsByTeamId/${id}`);
	},
	createProject(request) {
		return Client.post(`${resource}/projectmonitoring/projects`, request);
	},
	updateProject(request) {
		return Client.put(`${resource}/projectmonitoring/projects/${request.ID}`, request);
	},
	deleteProject(id) {
		return Client.delete(`${resource}/projectmonitoring/projects/${id}`);
	},
	approveProject(id) {
		return Client.patch(`${resource}/projectmonitoring/projects/${id}/approve`);
	},
	submitProject(payload) {
		return Client.put(`${resource}/projectmonitoring/projects/${payload.ID}/submit`, payload);
	},
	approveBulkProject(payload) {
		return Client.post(`${resource}/projectmonitoring/projects/approve`, payload);
	},
	rejectProject(payload) {
		return Client.put(`${resource}/projectmonitoring/projects/${payload.id}/reject`, { reason: payload.reason });
	},
	rejectBulkProject(payload) {
		return Client.post(`${resource}/projectmonitoring/projects/reject`, payload);
	},
	createProjectApplication(payload) {
		return Client.post(`${resource}/projectApplication/projectApplication`, payload);
	},
	getProjectApplicationsById(id) {
		return Client.get(`${resource}/projectApplication/projectApplications/ByProjectId/${id}`);
	},
	getProjectApplicationsCountById(id) {
		return Client.get(`${resource}/projectApplication/projectApplications/ByProjectId/${id}/Count`);
	},
	getProjectApplicationTeamsById(id) {
		return Client.get(`${resource}/projectmatching/projectapplications/${id}`);
	},
	getProjectApplicationByUserId() {
		return Client.get(`${resource}/projectApplication/projectApplications/ByUser`);
	},
	getProjectApplicationAppliedByUser() {
		return Client.get(`${resource}/projectApplication/projectApplications/AppliedByUser`);
	},
	getProjectApplicationHistoryByUser() {
		return Client.get(`${resource}/projectApplication/projectApplications/HistoryByUser`);
	},
	getProjectApplicationByCompanyId() {
		return Client.get(`${resource}/projectApplication/projectApplications/ByCompany`);
	},
	getProjectApplicationAdminDashboard(count) {
		return Client.get(`${resource}/projectApplication/projectApplications/ByAdmin/${count}`);
	},
	removeApplication(payload) {
		return Client.patch(`${resource}/projectApplication/projectApplications/removeFromList`, payload);
	},
	approveApplication(payload) {
		return Client.patch(`${resource}/projectApplication/projectApplications/approveTeam`, payload);
	},
	projectStatusCount() {
		return Client.get(`${resource}/projectmatching/statuscount/talent`);
	},
	clientStatusCount() {
		return Client.get(`${resource}/projectmatching/statuscount/client`);
	},
	clientUtilizedTalents() {
		return Client.get(`${resource}/projectmatching/talentutilized/client`);
	},
	addFile(request) {
		const formData = new FormData();
		request.Files.forEach((file) => { formData.append('files', file); });
		
		return Client.post(`${resource}/projectmonitoring/files/${request.ProjectId}`, formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
	},
	removeFile(request) {
		return Client.delete(`${resource}/projectmonitoring/files/${request.ProjectId}/${request.FileId}`);
	},
	getFile(request) {
		return Client.get(`${resource}/projectmonitoring/files/${request.ProjectId}/${request.FileId}`, {
			responseType: 'blob'
		});
	},
	updateProjectRating(request) {
		return Client.put(`${resource}/projectmonitoring/AddProjectRating/`, request);
	},
	getProjectsByCompanyId(payload) {
		return Client.post(`${resource}/projectmonitoring/projectsByCompanyId/name/${payload.name}`, payload.companyIds);
	},
	closeOutProject(payload) {
		return Client.patch(`${resource}/projectmonitoring/projects/${payload.id}/closed`, payload.body);
	},
	getProjectActivities(payload) {
		return Client.get(`${resource}/projectactivity?index=${payload.index}&size=${payload.size}`);
	},
	getSavedJobsAppliedByUser() {
		return Client.get(`${resource}/projectApplication/projectApplications/SavedProjectByUser`);
	},
	saveavailableProject(payload) {
		return Client.post(`${resource}/projectApplication/SavedProjects`, payload);
	},

};
import service from '../../services/factory-service';
import Vue from 'vue';

const userService = service.get("users");
const state = {
  profile: {
    UserID: localStorage.getItem('user-id') || '',
    FirstName: localStorage.getItem('user-first-name') || '',
    LastName: localStorage.getItem('user-last-name') || '',
    Email: localStorage.getItem('user-email') || '',
    UserType: localStorage.getItem('user-type') || '',
  },
  user: {
    ID: '',
    FirstName: '',
    LastName: '',
    Birthdate: '',
    Email: '',
    Phone: '',
    Country: '',
    State: '',
    City: '',
    Skills: [],
    Experiences: [],
    UserFiles: []
  },
  status: '',
  forApproval: [],
  list: [],
  rating: 0,
  tally: [0,0,0,0],
  regCount: {},
  avatar: "",
  countryTally: {},
  usercertificateList: [],
 // appliedCourses: [],
};

const getters = {
  profile: (userState) => userState.profile,
  forApproval: (userState) => userState.forApproval,
  list: (userState) => userState.list,
  user: (userState) => userState.user,
  rating: (userState) => userState.rating,
  tally: (userState) => userState.tally,
  regCount: (userState) => userState.regCount,
  avatar: (userState) => userState.avatar,
  countryTally: (userState) => userState.countryTally,
  usercertificateList: (userState) => userState.usercertificateList,
 // appliedCourses: state => state.appliedCourses,
};

const actions = {
  userRequest: ({ commit, dispatch }, id) => {
    commit('userRequest');
    userService.get(id)
      .then((result) => {
        localStorage.setItem('user-id', result.data.UserID);
        localStorage.setItem('user-first-name', result.data.FirstName);
        localStorage.setItem('user-last-name', result.data.LastName);
        localStorage.setItem('user-email', result.data.Email);
        localStorage.setItem('user-type', result.data.UserType);
        commit('userSuccess', result.data);
      },
        (errors) => {
          commit('userError', errors);
          dispatch('auth/authLogout', null, { root: true });
          localStorage.removeItem('user-id');
          localStorage.removeItem('user-first-name');
          localStorage.removeItem('user-last-name');
          localStorage.removeItem('user-email');
          localStorage.removeItem('user-type');
        });
  },
  getForApproval: ({ commit }) => {
    commit('userRequest');
    userService.getUsersByStatusId(1)
      .then((result) => {
        commit('userForApproval', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  getForApprovalBatch: ({ commit }, param) => {
    commit('userRequest');
    userService.getUsersByStatusIdBatch(param)
      .then((result) => {
        commit('userForApproval', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  get: ({ commit }) => {
    commit('userRequest');
    userService.getUsers()
      .then((result) => {
        commit('userList', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  getUserById: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit('userRequest');
      userService.getUserById(id)
        .then((result) => {
          commit('userDetails', result.data);
          resolve(result.data);
        },
          (errors) => {
            commit('userError', errors);
            reject(errors);
          });
    });
  },
  getProfile: ({ commit }) => {
    commit('userRequest');
    userService.getProfile()
      .then((result) => {
        commit('userDetails', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  updateUserStatus: ({ commit, dispatch }, request) => {
    commit('userRequest');
    userService.updateUserStatus(request)
      .then(() => {
        commit('updateSuccess');
        dispatch('user/getForApproval', null, { root: true });
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  updateUser: ({ commit }, request) => {
    commit('userRequest');
    userService.updateUserProfile(request)
      .then((result) => {
        commit('userDetails', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  updateUserSkills: ({ commit }, request) => {
    commit('userRequest');
    userService.updateUserSkills(request)
      .then((result) => {
        commit('userDetails', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  updateUserExperiences: ({ commit }, request) => {
    commit('userRequest');
    userService.updateUserExperiences(request)
      .then((result) => {
        commit('userDetails', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  updateSalaryRange: ({ commit }, request) => {
    commit('userRequest');
    userService.updateSalaryRange(request)
      .then((result) => {
        commit('userDetails', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  deleteUser: ({ commit, dispatch }, id) => {
    commit('userRequest');
    userService.deleteUser(id)
      .then(() => {
        commit('updateSuccess');
        dispatch('user/get', id, { root: true });
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  uploadFile: ({ commit }, request) => {
    commit('userRequest');
    userService.addFile(request)
      .then((result) => {
        commit('userDetails', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  removeFile: ({ commit }, fileid) => {
    commit('userRequest');
    userService.removeFile(fileid)
      .then((result) => {
        commit('userDetails', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  getFile: ({ commit }, request) => {
    commit('userRequest');
    userService.getFile(request.FileId)
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', request.Name);
        document.body.appendChild(link);
        link.click();
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  userLogout: ({ commit }) => {
    return new Promise((resolve) => {
      commit('userLogout');
      localStorage.removeItem('user-id');
      localStorage.removeItem('user-first-name');
      localStorage.removeItem('user-last-name');
      localStorage.removeItem('user-email');
      localStorage.removeItem('user-type');
      resolve();
    });
  },
  getTalentRatings: ({ commit }, id) => {
    commit('userRequest');
    userService.getTalentRatings(id)
      .then((result) => {
        commit('userRating', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  getTally: ({ commit }) => {
    commit('userRequest');
    userService.getTally()
      .then((result) => {
        commit('userTally', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  getRegistrationCount: ({ commit }) => {
    commit('userRequest');
    userService.getRegistrationCount()
      .then((result) => {
        commit('userRegCount', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  getCountryTally: ({ commit }) => {
    commit('userRequest');
    userService.getCountryTally()
      .then((result) => {
        commit('userCountryTally', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  uploadAvatar: ({ commit }, request) => {
    commit('userRequest');
    userService.uploadAvatar(request)
      .then((result) => {
        commit('userAvatar', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  getAvatar: ({ commit }, request) => {
    commit('userRequest');
    userService.getAvatar(request)
      .then((result) => {
        commit('userAvatar', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  addCommunity: ({ commit }, id) => {
    commit('userRequest');
    userService.addCommunity(id)
      .then((result) => {
        commit('userDetails', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  removeCommunity: ({ commit }, id) => {
    commit('userRequest');
    userService.removeCommunity(id)
      .then((result) => {
        commit('userDetails', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
  
  //For Certifificate Application
  applyForCertificate(_, { userId, firstName, lastName, certificateName, certId }) {
    return new Promise((resolve, reject) => {
      userService.applyForCertificate(userId, lastName, firstName, certificateName, certId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // For Course Application
  applyForCourse(_, { userId, firstName, lastName, email, courseTitle, certificateID }) {
    return new Promise((resolve) => {
      userService.applyForCourse(userId, firstName, lastName, email, courseTitle, certificateID)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          if (error.response && error.response.status === 409) {
            resolve({ status: 409, message: "User already applied for this course." });
          } else {
            resolve(null);
          }
        });
    });
  },

  fetchAppliedCourses: ({ commit }) => {
    commit('userRequest');
    userService.getAppliedCourses()
      .then((result) => {
        commit('setAppliedCourses', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },

  fetchUserCertificates: ({ commit }) => {
    commit('userRequest');
    userService.getUserCertificates()
      .then((result) => {
        commit('setUserCertificates', result.data);
      },
        (errors) => {
          commit('userError', errors);
        });
  },
};

const mutations = {
  userRequest: (userState) => {
    userState.status = 'attempting request for user profile data';
  },
  userSuccess: (userState, userResp) => {
    userState.status = 'success';
    Vue.set(userState, 'profile', userResp);
  },
  userList: (userState, list) => {
    userState.status = 'success';
    Vue.set(userState, 'list', list);
  },
  userForApproval: (userState, list) => {
    userState.status = 'success';
    Vue.set(userState, 'forApproval', list);
  },
  userDetails: (userState, user) => {
    userState.status = 'success';
    Vue.set(userState, 'user', user);
  },
  updateSuccess: (userState) => {
    userState.status = 'success';
  },
  userError: (userState) => {
    userState.status = 'error';
  },
  userLogout: (userState) => {
    userState.status = '';
  },
  userRating: (userState, rating) => {
    userState.rating = rating;
  },
  userTally: (userState, tally) => {
    userState.tally = tally;
  },
  userCountryTally: (userState, tally) => {
    userState.countryTally = tally;
  },
  userRegCount: (userState, data) => {
    userState.regCount = data;
  },
  userAvatar: (userState, url) => {
    userState.avatar = url;
  },
  allUsersCourses: (userState, list) => {
    userState.status = 'success';
    Vue.set(userState, 'allUserCourses', list);
  },
  setAppliedCourses: (userState, list) => {
    userState.status = 'success';
    Vue.set(userState, 'usercoursesList', list);
  },
  setUserCertificates: (userState, list) => {
    userState.status = 'success';
    Vue.set(userState, 'usercertificateList', list);
  },
  // setAppliedCourses(state, courses) {
  //   state.appliedCourses = courses;
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};